import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import Container from './cards.style'
import Card from '../Card'

const Cards = () => {
  const { locale } = useIntl()
  const data = useStaticQuery(graphql`
    query {
      postgres {
        items(last: 8) {
          nodes {
            price
            name_en: nameEn
            name_ar: nameAr
            name_he: nameHe
            imageUrlCompressed
            id
            category
            model
          }
        }
      }
    }
  `)
  return (
    <Container>
      {data.postgres.items.nodes.map(node => (
        <Card
          price={node.price}
          name={node[`name_${locale}`]}
          imageUrlCompressed={node.imageUrlCompressed}
          id={node.id}
          category={node.category}
          model={node.model}
          key={node.id}
        />
      ))}
    </Container>
  )
}

export default Cards
