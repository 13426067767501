import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { css } from 'styled-components'

import Cards from '../components/Cards'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import CategoryCard from '../components/CategoryCard'
import { H3 } from '../components/typography/index'

import { categories } from '../utils'

const IndexPage = () => {
  const intl = useIntl()
  return (
    <Layout>
      <SEO
        dir={intl.locale === 'en' ? 'ltr' : 'rtl'}
        title={intl.formatMessage({ id: 'title' })}
        lang={intl.locale}
        description={intl.formatMessage({ id: 'meta_desc' })}
      />
      <div
        css={css`
          margin: 10rem 9.5rem;

          @media (max-width: 600px) {
            margin: 5rem 3.5rem;
          }
        `}
      >
        <H3>{intl.formatMessage({ id: 'categories' })}</H3>
        <div
          css={css`
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            margin-top: 5rem;

            @media (max-width: 600px) {
              margin-top: 3rem;
            }
          `}
        >
          {categories.map(({ name, path }) => (
            <div
              css={css`
                width: 28.5rem;
                margin-bottom: 1.5rem;
                @media (max-width: 600px) {
                  width: 45%;
                }
              `}
            >
              <CategoryCard key={name} name={name} path={path} />
            </div>
          ))}
        </div>
        <Cards />
      </div>
    </Layout>
  )
}
export default IndexPage
