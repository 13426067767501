import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin: 15rem auto;

  @media (max-width: 600px) {
    margin: 6rem auto;
  }
`
